import { faDeleteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { firestore } from './Firebase';
import toast from 'react-hot-toast';

const AdminDirections = () => {
  const [updatedDirections, setUpdatedDirections] = useState('');

  useEffect(() => {
    const collectionRef = firestore.collection('config');
    const documentId = 'config';

    collectionRef
      .doc(documentId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          const fieldValue = data.directions;
          setUpdatedDirections(fieldValue);
        } else {
          collectionRef
            .doc(documentId)
            .set({
              new: '',
            })
            .catch((error) => {
              console.error('Error adding document:', error);
            });
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }, []);

  const handleUpdate = async () => {
    try {
      await firestore.collection('config').doc('config').update({
        directions: updatedDirections,
      });
      toast.success('Directions updated successfully');
    } catch (error) {
      toast.error('Error updating directions');
    }
  };

  const handleBlur = () => {};
  const handleClear = async () => {
    try {
      await firestore.collection('config').doc('config').update({
        directions: '',
      });
      toast.success('Directions cleared');
    } catch (error) {
      toast.error('Error clearing directions');
    }
  };

  return (
    <>
      <div className="d-flex mb-1">
        <div className="flex-grow-1 my-1 ms-1">
          <textarea
            rows="4"
            className="form-control"
            value={updatedDirections}
            onChange={(e) => setUpdatedDirections(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                handleUpdate(e.target.value);
              }
            }}
            onBlur={(e) => handleBlur(e.target.value)}
          />
        </div>
        <div>
          <Button
            variant="danger"
            className="mt-1 px-3 me-1"
            onClick={() => {
              handleClear();
              setUpdatedDirections('');
            }}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AdminDirections;
